<template>
  <v-container>
    <v-text-field
      v-model="issueDate"
      label="Fecha"
      hide-details="auto"
      required
      outlined
      type="date"
      :rules="f_required"
      dense
      prepend-icon="mdi-cash-register"
      append-outer-icon="mdi-magnify"
      @input="get_report"
      @click:append-outer="get_report"
    />
    <v-row>
      <v-col cols="12" md="6">
        <v-card flat>
          <v-card-title> Ventas por Documentos </v-card-title>
          <v-card-text class="pa-0">
            <v-data-table
              :headers="headersDocType"
              :items="docType"
              :items-per-page="10"
              item-key="id"
              sort-by="name"
              mobile-breakpoint="0"
              class="elevation-4"
              :loading="loading_status"
              loader-height="10"
              loading-text="Cargando ..."
              dense
            >
              <template #top> </template>
              <template v-slot:[`item.qty`]="{ item }">
                <span>{{ parseFloat(item.qty).toLocaleString("es") }}</span>
              </template>
              <template v-slot:[`item.value`]="{ item }">
                <span>{{ parseFloat(item.value).toLocaleString("es") }}</span>
              </template>
              <template slot="body.append">
                <tr>
                  <th class="title">Total</th>
                  <th class="text-center">{{ sumTable("cash_type", "qty") }}</th>
                  <th class="text-right">{{ sumTable("cash_type", "value") }}</th>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card flat>
          <v-card-title>Ventas por Vendedor</v-card-title>
          <v-card-text class="pa-0">
            <v-data-table
              :headers="headersDocType"
              :items="Vendors"
              :items-per-page="10"
              item-key="id"
              sort-by="name"
              mobile-breakpoint="0"
              class="elevation-4"
              :loading="loading_status"
              loader-height="10"
              loading-text="Cargando ..."
              dense
            >
              <template #top> </template>
              <template v-slot:[`item.qty`]="{ item }">
                <span>{{ parseFloat(item.qty).toLocaleString("es") }}</span>
              </template>
              <template v-slot:[`item.value`]="{ item }">
                <span>{{ parseFloat(item.value).toLocaleString("es") }}</span>
              </template>
              <template slot="body.append">
                <tr>
                  <th class="title">Total</th>
                  <th class="text-center">{{ sumTable("cash_type", "qty") }}</th>
                  <th class="text-right">{{ sumTable("cash_type", "value") }}</th>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="12">
        <v-card flat>
          <v-card-title> Ventas por forma de pago </v-card-title>
          <v-card-text class="pa-0">
            <v-data-table
              :headers="headers_cash"
              :items="cash_type"
              :items-per-page="10"
              item-key="id"
              sort-by="name"
              mobile-breakpoint="0"
              class="elevation-4"
              :loading="loading_status"
              loader-height="10"
              loading-text="Cargando ..."
              dense
            >
              <template #top> </template>
              <template v-slot:[`item.qty`]="{ item }">
                <span>{{ parseFloat(item.qty).toLocaleString("es") }}</span>
              </template>
              <template v-slot:[`item.value`]="{ item }">
                <span>{{ parseFloat(item.value).toLocaleString("es") }}</span>
              </template>
              <template slot="body.append">
                <tr>
                  <th class="title">Total</th>
                  <th class="text-center">{{ sumTable("cash_type", "qty") }}</th>
                  <th class="text-right">{{ sumTable("cash_type", "value") }}</th>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- <v-col cols="12" md="6">
        <GChart
          type="PieChart"
          :data="chartPayments"
          :options="chartOptions"
          style="width: 100%; height: 400px"
        />
      </v-col> -->
    </v-row>

    <v-data-table
      :headers="headers_docs"
      :items="cash_data"
      :items-per-page="10"
      mobile-breakpoint="0"
      item-key="id"
      sort-by="name"
      class="elevation-4 mt-6"
      :loading="loading_status_docs"
      loader-height="10"
      loading-text="Cargando ..."
      dense
    >
      <template #top>
        <v-toolbar flat>
          <b>Documentos</b>
          <v-spacer></v-spacer>
          <!-- <v-btn icon @click="downloadExcel"><v-icon>mdi-microsoft-excel</v-icon></v-btn> -->
        </v-toolbar>
      </template>

      <template v-slot:[`item.qty`]="{ item }">
        <span>{{ parseFloat(item.qty).toLocaleString("es") }}</span>
      </template>
      <template v-slot:[`item.value`]="{ item }">
        <span>{{ parseFloat(item.value).toLocaleString("es") }}</span>
      </template>
      <template v-slot:[`item.cufe`]="{ item }">
        <v-icon small class="mr-2" v-if="item.cufe"> mdi-check-all </v-icon>
      </template>
      <template v-slot:[`item.dian_id`]="{ item }">
        <v-icon small class="mr-2" v-if="item.dian_id"> mdi-check-all </v-icon>
      </template>
      <template v-slot:[`item.cufe_n`]="{ item }">
        <v-icon small class="mr-2" v-if="item.cufe_n"> mdi-check-all </v-icon>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="get_e_doc(item)"> mdi-magnify </v-icon>
      </template>
      <template slot="body.append">
        <!-- <tr>
          <th class="title">Total</th>
          <th></th>
          <th class="text-center ">{{ sumTable('cash_data', 'quantity') }}</th>
          <th class="text-right ">{{ sumTable('cash_data', 'descuento') }}</th>
          <th class="text-right ">{{ sumTable('cash_data', 'valor') }}</th>
        </tr> -->
      </template>
    </v-data-table>
    <docViewer
      :item="item"
      :dialog="dialog"
      @close="dialog = false"
      @refresh="refresh()"
    />
  </v-container>
</template>

<script>
import { webserver, getToday } from "../services/webserver.js";
import docViewer from "../components/doc_viewer.vue";
import createDoc from "../utils/create_doc.js";
import * as XLSX from "xlsx";
// import { GChart } from "vue-google-charts";

export default {
  components: { docViewer },
  data() {
    return {
      f_required: [(v) => !!v || "Requerido"],
      loading_status: false,
      loading_status_docs: false,
      searchvalid: false,
      issueDate: getToday(),
      headers: [
        {
          text: "Documento ID",
          align: "start",
          sortable: true,
          value: "order_num",
          dataType: "text",
        },
        {
          text: "Cliente",
          align: "start",
          sortable: true,
          value: "client_name",
          dataType: "text",
        },
        {
          text: "Cantidad",
          align: "center",
          sortable: true,
          value: "quantity",
          dataType: "number",
        },
        {
          text: "Descuento",
          align: "end",
          sortable: true,
          value: "descuento",
          dataType: "number",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "valor",
          dataType: "number",
        },

        { text: "", value: "actions", align: "end", sortable: false },
      ],
      headers_cash: [
        {
          text: "Forma de pago",
          align: "start",
          sortable: true,
          value: "label",
          dataType: "text",
        },
        {
          text: "Cantidad",
          align: "center",
          sortable: true,
          value: "qty",
          dataType: "number",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "value",
          dataType: "number",
        },
      ],
      headersDocType: [
        {
          text: "Documento",
          align: "start",
          sortable: true,
          value: "label",
          dataType: "text",
        },
        {
          text: "Cantidad",
          align: "center",
          sortable: true,
          value: "qty",
          dataType: "number",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "value",
          dataType: "number",
        },
      ],
      headers_docs: [
        {
          text: "Fecha",
          align: "start",
          sortable: true,
          value: "issuedate",
          dataType: "text",
        },
        {
          text: "Documento",
          align: "start",
          sortable: true,
          value: "doc_number",
          dataType: "text",
        },

        {
          text: "Factura",
          align: "start",
          sortable: true,
          value: "invoice",
          dataType: "text",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "doc_value",
          dataType: "number",
        },
        {
          text: "ZIP",
          align: "end",
          sortable: true,
          value: "dian_id",
          dataType: "text",
        },
        {
          text: "CUFE",
          align: "end",
          sortable: true,
          value: "cufe",
          dataType: "number",
        },
        { text: "", align: "end", value: "actions", sortable: false, width: "5" },
      ],
      cash_data: [],
      cash_type: [],
      docType: [],
      Vendors: [],
      dialog: false,
      item: createDoc(),
      chartPayments: ["Evento", "Cantidad"],
      chartOptions: {
        curveType: "function",
        legend: { position: "right" },
        interpolateNulls: true,
        crosshair: { trigger: "both", opacity: 0.95, color: "#39FF14" },
        title: "",
        isStacked: false,
        hAxis: {
          title: "",
          slantedText: true,
          slantedTextAngle: 15,
        },
        colors: ["#1b9e77", "#d95f02", "#7570b3"],
        height: 400,
      },
    };
  },
  mounted() {
    this.get_report();
  },
  methods: {
    downloadExcel() {
      const ws = XLSX.utils.json_to_sheet(this.cash_data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "cash_data.xlsx");
    },
    sumTable(table, key) {
      // sum data in give key (property)
      return parseFloat(
        this[table].reduce((a, b) => a + (parseFloat(b[key]) || 0), 0)
      ).toLocaleString(2);
    },
    showInvoice(e) {
      this.loading_status = true;
      var qry = {
        store: window.store.store_id,
        dateTo: e.order_date,
        did: e.order_number,
      };
      webserver("get_invoice", qry, (data) => {
        //console.log(data);
        this.item = { ...data };
        this.dialog = true;
        this.loading_status = false;
      });
    },
    get_e_doc(e) {
      var issudate = e.issuedate.split(" ");
      console.log(issudate[0]);
      var doc = {
        store: e.store,
        issueDate: issudate[0],
        did: e.doc_number,
      };
      console.log(doc);
      webserver("get_invoice_fe", doc, (data) => {
        console.log(data);
        this.item = data;
        this.dialog = true;
        // data.doc.doc_type = "Invoice";
        this.loading_vendors = false;
      });
    },
    async get_report() {
      this.loading_status = true;

      var qry = {
        store: window.store.store_id,
        date: this.issueDate,
        userId: window.profile.user_email,
      };

      let promise = new Promise((resolve, reject) => {
        webserver(
          "get_cashdrawer_report",
          qry,
          function (data) {
            resolve(data);
          },
          function () {
            reject([]);
          }
        );
      });

      let dat = await promise;
      console.log("====>", data);
      let data = dat.payments;
      var result = [];
      var dtSet = [];
      dtSet.push(["Forma de pago", "Valor"]);
      dtSet.push(["", 0]);

      data.reduce(function (res, value) {
        if (!res[value.payment_concept]) {
          res[value.payment_concept] = { label: value.payment_concept, qty: 0, value: 0 };
          result.push(res[value.payment_concept]);
        }
        res[value.payment_concept].qty += 1;
        res[value.payment_concept].value += parseFloat(value.payment_value);
        return res;
      }, {});

      result.sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
      this.cash_type = result;

      var docTypes = [];
      data.reduce(function (res, value) {
        if (!res[value.doc_type]) {
          res[value.doc_type] = { label: value.doc_type, qty: 0, value: 0 };
          docTypes.push(res[value.doc_type]);
        }
        res[value.doc_type].qty += 1;
        res[value.doc_type].value += parseFloat(value.payment_value);
        return res;
      }, {});

      this.docType = docTypes;

      this.Vendors = [];
      result = [];
      data.reduce(function (res, value) {
        if (!res[value.userId]) {
          res[value.userId] = { label: value.userId, qty: 0, value: 0 };
          result.push(res[value.userId]);
        }
        res[value.userId].qty += 1;
        res[value.userId].value += parseFloat(value.payment_value);
        return res;
      }, {});
      this.Vendors = result;

      result.forEach((rg) => {
        var item = [];
        item.push(rg.label);
        item.push(rg.value);
        dtSet.push(item);
      });

      this.chartPayments = dtSet;
      this.loading_status = false;
      this.get_docs();
    },
    async get_docs() {
      this.loading_status_docs = true;
      var qry = {
        store: window.store.store_id,
        table: "docs",
      };
      qry.filters = [
        {
          field: "doc_type",
          operator: " IN ",
          value: "'invoice','pos','nc'",
        },
        {
          field: "DATE(issuedate)",
          operator: "=",
          value: getToday(),
        },
        {
          field: "store",
          operator: "=",
          value: window.store.store_id,
        },
      ];
      if (this.$store.getters.profile.user_access == "USUARIO") {
        qry.filters.push({
          field: "userId",
          operator: "=",
          value: this.$store.state.profile.email,
        });
      }
      let promise = new Promise((resolve, reject) => {
        webserver(
          "get_dataTable",
          qry,
          function (data) {
            resolve(data);
          },
          function () {
            reject([]);
          }
        );
      });
      let data = await promise;
      console.log("=xx===>", data);
      var result = [];
      var dtSet = [];
      dtSet.push(["Forma de pago", "Valor"]);
      dtSet.push(["", 0]);
      data.reduce(function (res, value) {
        if (!res[value.doc_type]) {
          res[value.doc_type] = { label: value.doc_type, qty: 0, value: 0 };
          result.push(res[value.doc_type]);
        }
        res[value.doc_type].qty += 1;
        res[value.doc_type].value += parseFloat(value.doc_value);
        return res;
      }, {});

      result.sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
      //console.log("tipos", result);

      // result.forEach((rg) => {
      //   var item = [];
      //   item.push(rg.label);
      //   item.push(rg.value);
      //   dtSet.push(item);
      // });

      // this.chartPayments = dtSet;

      this.loading_status_docs = false;
      this.cash_data = data;
      //console.log(data);
    },
  },
};
</script>

<style></style>
