var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-text-field',{attrs:{"label":"Fecha","hide-details":"auto","required":"","outlined":"","type":"date","rules":_vm.f_required,"dense":"","prepend-icon":"mdi-cash-register","append-outer-icon":"mdi-magnify"},on:{"input":_vm.get_report,"click:append-outer":_vm.get_report},model:{value:(_vm.issueDate),callback:function ($$v) {_vm.issueDate=$$v},expression:"issueDate"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" Ventas por Documentos ")]),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"elevation-4",attrs:{"headers":_vm.headersDocType,"items":_vm.docType,"items-per-page":10,"item-key":"id","sort-by":"name","mobile-breakpoint":"0","loading":_vm.loading_status,"loader-height":"10","loading-text":"Cargando ...","dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return undefined},proxy:true},{key:"item.qty",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(parseFloat(item.qty).toLocaleString("es")))])]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(parseFloat(item.value).toLocaleString("es")))])]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',{staticClass:"title"},[_vm._v("Total")]),_c('th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.sumTable("cash_type", "qty")))]),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.sumTable("cash_type", "value")))])])])],2)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("Ventas por Vendedor")]),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"elevation-4",attrs:{"headers":_vm.headersDocType,"items":_vm.Vendors,"items-per-page":10,"item-key":"id","sort-by":"name","mobile-breakpoint":"0","loading":_vm.loading_status,"loader-height":"10","loading-text":"Cargando ...","dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return undefined},proxy:true},{key:"item.qty",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(parseFloat(item.qty).toLocaleString("es")))])]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(parseFloat(item.value).toLocaleString("es")))])]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',{staticClass:"title"},[_vm._v("Total")]),_c('th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.sumTable("cash_type", "qty")))]),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.sumTable("cash_type", "value")))])])])],2)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" Ventas por forma de pago ")]),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"elevation-4",attrs:{"headers":_vm.headers_cash,"items":_vm.cash_type,"items-per-page":10,"item-key":"id","sort-by":"name","mobile-breakpoint":"0","loading":_vm.loading_status,"loader-height":"10","loading-text":"Cargando ...","dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return undefined},proxy:true},{key:"item.qty",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(parseFloat(item.qty).toLocaleString("es")))])]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(parseFloat(item.value).toLocaleString("es")))])]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',{staticClass:"title"},[_vm._v("Total")]),_c('th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.sumTable("cash_type", "qty")))]),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.sumTable("cash_type", "value")))])])])],2)],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-4 mt-6",attrs:{"headers":_vm.headers_docs,"items":_vm.cash_data,"items-per-page":10,"mobile-breakpoint":"0","item-key":"id","sort-by":"name","loading":_vm.loading_status_docs,"loader-height":"10","loading-text":"Cargando ...","dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('b',[_vm._v("Documentos")]),_c('v-spacer')],1)]},proxy:true},{key:"item.qty",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(parseFloat(item.qty).toLocaleString("es")))])]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(parseFloat(item.value).toLocaleString("es")))])]}},{key:"item.cufe",fn:function(ref){
var item = ref.item;
return [(item.cufe)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-check-all ")]):_vm._e()]}},{key:"item.dian_id",fn:function(ref){
var item = ref.item;
return [(item.dian_id)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-check-all ")]):_vm._e()]}},{key:"item.cufe_n",fn:function(ref){
var item = ref.item;
return [(item.cufe_n)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-check-all ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.get_e_doc(item)}}},[_vm._v(" mdi-magnify ")])]}}],null,true)},[_c('template',{slot:"body.append"})],2),_c('docViewer',{attrs:{"item":_vm.item,"dialog":_vm.dialog},on:{"close":function($event){_vm.dialog = false},"refresh":function($event){return _vm.refresh()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }